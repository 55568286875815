<template>
    <div>
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item
                :to="{ path: '/system_Management/system_management_home' }"
                >系统管理</el-breadcrumb-item
            >
            <el-breadcrumb-item>账号列表</el-breadcrumb-item>
        </el-breadcrumb>
        <el-card>
            <el-row :gutter="20">
                <el-col :span="6">
                    <el-input
                        placeholder="请输入姓名或手机号"
                        v-model="queryParams.userName"
                        clearable=""
                        @clear="getAccountList"
                    >
                        <el-button
                            slot="append"
                            icon="el-icon-search"
                            @click="getAccountList"
                        ></el-button>
                    </el-input>
                </el-col>
                <el-col :span="4">
                    <el-button
                        type="primary"
                        @click="addAccountDialogVisible = true"
                        >添加账号</el-button
                    >
                </el-col>
            </el-row>
            <!-- 用户列表表格区域 -->
            <el-table
                header-cell-class-name="tableHeaderStyle"
                :data="accountList"
                @expand-change="handledetail"
                :expand-row-keys="currentExpandRow"
                row-key="id"
                border
                stripe
            >
                <el-table-column type="expand">
                    <template slot-scope="props">
                        <el-table
                            header-cell-class-name="tableHeaderStyle"
                            :data="props.row.userRoleList"
                            border
                            stripe
                        >
                            <el-table-column
                                label="部门"
                                prop="deptName"
                            ></el-table-column>
                            <el-table-column
                                label="岗位"
                                prop="rname"
                            ></el-table-column>
                            <el-table-column label="操作">
                                <template slot-scope="scope">
                                    <el-button
                                        type="primary"
                                        icon="el-icon-edit"
                                        size="mini"
                                        @click="showEditUserDialog(scope.row)"
                                    ></el-button>
                                    <el-button
                                        type="danger"
                                        icon="el-icon-delete"
                                        size="mini"
                                        @click="showDeleteUserTip(scope.row)"
                                    ></el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </template>
                </el-table-column>
                <el-table-column label="#" type="index"></el-table-column>
                <el-table-column label="姓名" prop="userName"></el-table-column>
                <el-table-column label="邮箱" prop="email"></el-table-column>
                <el-table-column label="电话" prop="phone"></el-table-column>
                <el-table-column label="状态">
                    <template slot-scope="scope">
                        <el-switch
                            v-model="scope.row.state"
                            @change="accountStateChange(scope.row)"
                        ></el-switch>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="220px">
                    <template slot-scope="scope">
                        <el-button
                            type="primary"
                            icon="el-icon-edit"
                            size="mini"
                            @click="showEditDialog(scope.row.id)"
                            >编辑</el-button
                        >
                        <el-tooltip
                            effect="dark"
                            content="添加岗位"
                            placement="top"
                            :enterable="false"
                        >
                            <el-button
                                type="warning"
                                icon="el-icon-plus"
                                size="mini"
                                @click="showAddUserDialog(scope.row.id)"
                                >添加岗位</el-button
                            >
                        </el-tooltip>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页区域 -->
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="queryParams.pagenum"
                :page-sizes="[10, 20, 40, 80]"
                :page-size="queryParams.pagesize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
            >
            </el-pagination>
        </el-card>
        <!-- 添加账号Dialog -->
        <el-dialog
            title="添加账号"
            :visible.sync="addAccountDialogVisible"
            width="50%"
            @close="addAccountDialogClose"
        >
            <el-form
                ref="addAccountFormRef"
                :model="addAccountForm"
                :rules="addAccountFormRules"
            >
                <el-form-item label="手机" prop="phone" label-width="70px">
                    <el-input v-model="addAccountForm.phone"></el-input>
                </el-form-item>
                <el-form-item label="用户名" prop="userName" label-width="70px">
                    <el-input v-model="addAccountForm.userName"></el-input>
                </el-form-item>
                <el-form-item label="性别" prop="sex" label-width="70px">
                    <el-select
                        placeholder="请选择"
                        style="width: 100%"
                        v-model="addAccountForm.sex"
                        clearable
                    >
                        <el-option
                            v-for="item in dict_sex"
                            :key="item.dictValue"
                            :label="item.dictLabel"
                            :value="item.dictValue"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="邮箱" prop="email" label-width="70px">
                    <el-input v-model="addAccountForm.email"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="addAccountDialogVisible = false"
                    >取 消</el-button
                >
                <el-button type="primary" @click="addAccount">确 定</el-button>
            </span>
        </el-dialog>
        <!-- 编辑账号Dialog -->
        <el-dialog
            title="编辑账号"
            :visible.sync="editAccountDialogVisible"
            width="50%"
            @close="editAccountDialogClose"
        >
            <el-form
                ref="editAccountFormRef"
                :model="editAccountForm"
                :rules="addAccountFormRules"
            >
                <el-form-item label="用户名" prop="userName" label-width="70px">
                    <el-input v-model="editAccountForm.userName"></el-input>
                </el-form-item>
                <el-form-item label="密码" prop="passwd" label-width="70px">
                    <el-input v-model="editAccountForm.passwd"></el-input>
                </el-form-item>
                <el-form-item label="性别" prop="sex" label-width="70px">
                    <el-select
                        placeholder="请选择"
                        style="width: 100%"
                        v-model="editAccountForm.sex"
                        clearable
                    >
                        <el-option
                            v-for="item in dict_sex"
                            :key="item.dictValue"
                            :label="item.dictLabel"
                            :value="item.dictValue"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="邮箱" prop="email" label-width="70px">
                    <el-input v-model="editAccountForm.email"></el-input>
                </el-form-item>
                <el-form-item label="手机" prop="phone" label-width="70px">
                    <el-input v-model="editAccountForm.phone"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="editAccountDialogVisible = false"
                    >取 消</el-button
                >
                <el-button type="primary" @click="editAccount">确 定</el-button>
            </span>
        </el-dialog>
        <!-- 添加岗位Dialog -->
        <el-dialog
            title="添加岗位"
            :visible.sync="addUserDialogVisible"
            width="30%"
            @close="addUserDialogClose"
        >
            <el-form
                ref="addUserFormRef"
                :model="addUserForm"
                :rules="addUserFormRules"
                label-width="80px"
            >
                <el-form-item label="所属部门" prop="deptIdList">
                    <el-cascader
                        placeholder="请选择"
                        style="width: 100%"
                        ref="addUserFormSelectDept"
                        :show-all-levels="false"
                        :options="deptList"
                        :props="{
                            checkStrictly: true,
                            label: 'name',
                            value: 'id',
                            children: 'childs',
                        }"
                        v-model="addUserForm.deptIdList"
                    ></el-cascader>
                </el-form-item>
                <el-form-item label="岗位名称" prop="roleId">
                    <el-select
                        v-model="addUserForm.roleId"
                        placeholder="请选择"
                        style="width: 100%"
                        ref="addUserFormSelectRole"
                    >
                        <el-option
                            v-for="item in rolesList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="addUserDialogVisible = false"
                    >取 消</el-button
                >
                <el-button type="primary" @click="addUser">确 定</el-button>
            </span>
        </el-dialog>
        <!-- 编辑用户Dialog -->
        <el-dialog
            title="编辑岗位"
            :visible.sync="editUserDialogVisible"
            width="30%"
            @close="editUserDialogClose"
        >
            <el-form
                ref="editUserFormRef"
                :model="editUserForm"
                :rules="addUserFormRules"
                label-width="80px"
            >
                <el-form-item label="所属部门" prop="deptIdList">
                    <el-cascader
                        placeholder="请选择"
                        style="width: 100%"
                        :show-all-levels="false"
                        ref="editUserFormSelectDept"
                        :options="deptList"
                        :props="{
                            checkStrictly: true,
                            label: 'name',
                            value: 'id',
                            children: 'childs',
                        }"
                        v-model="editUserForm.deptIdList"
                    ></el-cascader>
                </el-form-item>
                <el-form-item label="岗位名称" prop="roleId">
                    <el-select
                        v-model="editUserForm.roleId"
                        placeholder="请选择"
                        style="width: 100%"
                        ref="editUserFormSelectRole"
                    >
                        <el-option
                            v-for="item in rolesList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="editUserDialogVisible = false"
                    >取 消</el-button
                >
                <el-button type="primary" @click="editUser">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { updateState, getQueryErpUserList, erpEditSysUserIdentity, erpDeleteSysUserIdentity, getSysUserIdentityList, addSystemUser, editSystemUser, getSysUserinfo, getSectionList, getRoleListData, erpSysUserIdentityCreate } from '@/http/api'
export default {
  data () {
    return {
      currentExpandRow: [],
      getRowKeys (row) {
        return row.id
      },
      userRoleList: [],
      queryParams: {
        userName: '',
        pageNum: 1,
        pageSize: 10
      },
      accountList: [],
      total: 0,
      addAccountDialogVisible: false,
      addAccountForm: {
        userName: '',
        passwd: '',
        email: '',
        phone: '',
        sex: ''
      },
      addAccountFormRules: {
        userName: [{ required: true, message: '请输入用户名', trigger: 'blur' }],
        passwd: [{ required: true, message: '请输入密码', trigger: 'blur' }],
        email: [{ required: true, message: '请输入邮箱', trigger: 'blur' }],
        phone: [{ required: true, message: '请输入手机', trigger: 'blur' }],
        sex: [{ required: true, message: '请输入手机', trigger: 'change' }]
      },
      editAccountForm: {},
      editAccountDialogVisible: false,
      userList: [],
      setUserDialogVisible: false,
      setUserSelectId: [],
      erpUserId: '',
      willSetUserAcountName: '',
      addUserDialogVisible: false,
      addUserForm: {
        erpUserId: '',
        deptId: '',
        deptIdList: [],
        roleId: ''
      },
      addUserFormRules: {
        deptName: [{ required: true, message: '请选择部门', trigger: 'change' }],
        roleName: [{ required: true, message: '请选择岗位', trigger: 'change' }]
      },
      dict_sex: this.$userInfo.dict_sex(),
      deptList: [],
      rolesList: [],
      editUserDialogVisible: false,
      editUserForm: {
        erpUserId: '',
        deptId: '',
        deptIdList: [],
        roleId: ''
      }
    }
  },
  created () {
    this.getAccountList()
    this.getDeptList()
    this.getRoleList()
  },
  methods: {
    async getAccountList () {
      getQueryErpUserList(this.queryParams).then((res) => {
        this.accountList = res.data.list
        this.total = res.data.total
      }).catch((err) => {
        console.log('err', err)
      })
    },
    getDeptList () {
      getSectionList(this.addAccountForm).then((res) => {
        this.deptList = res.data
      }).catch((err) => {
        console.log('err', err)
      })
    },
    getRoleList () {
      getRoleListData().then((res) => {
        this.rolesList = res.data
      }).catch((err) => {
        console.log('err', err)
      })
    },
    handleSizeChange (pageSize) {
      this.queryParams.pageSize = pageSize
      this.getAccountList()
    },
    handleCurrentChange (pageNum) {
      this.queryParams.pageNum = pageNum
      this.getAccountList()
    },
    addAccountDialogClose () {
      this.$refs.addAccountFormRef.resetFields()
    },
    // 添加账号提交
    addAccount () {
      this.$refs.addAccountFormRef.validate(async valid => {
        if (!valid) return // 预校验失败
        addSystemUser(this.addAccountForm).then((res) => {
          this.$message.success('添加账号成功')
          this.addAccountDialogVisible = false
          this.getAccountList()
        }).catch((err) => {
          console.log('err', err)
        })
      })
    },
    // 编辑账号信息
    showEditDialog (userId) {
      getSysUserinfo(userId).then((res) => {
        this.editAccountForm = res.data
        this.editAccountDialogVisible = true
      }).catch((err) => {
        console.log('err', err)
      })
    },
    // 编辑账号提交
    editAccount () {
      editSystemUser(this.editAccountForm).then((res) => {
        this.$message.success('编辑账号成功')
        this.addAccountDialogVisible = false
        this.getAccountList()
      }).catch((err) => {
        console.log('err', err)
      })
    },
    // 编辑用户状态
    accountStateChange (data) {
      console.log('data', data)
      var formData = {
        userId: Number(data.id),
        state: data.state
      }
      updateState(formData).then((res) => {
        if (res.code === 200) {
          this.$message.success('修改账号状态成功')
        } else {
          return this.$message.warning(res.message)
        }
      }).catch((err) => {
        console.log('err', err)
      })
    },
    editAccountDialogClose () {
      this.$refs.editAccountFormRef.resetFields()
    },
    editDialogClose () {
      this.$refs.editFormRef.resetFields()
      this.editAccountForm = {}
    },
    // 打开子菜单点击事件
    handledetail (row, expandedRows) {
      console.log(row, expandedRows)
      if (expandedRows.length > 0) {
        this.currentExpandRow = []
        getSysUserIdentityList({ userId: row.id }).then((res) => {
          let currentIndex = 0
          this.accountList.some((item, index) => {
            if (item.id === row.id) {
              currentIndex = index
              return true
            }
          })
          const groupID = row.id
          this.currentExpandRow = [groupID] // 控制展开行
          this.$set(this.accountList[currentIndex], 'userRoleList', res.data)
        }).catch((err) => {
          console.log('err', err)
        })
      }
    },
    // 显示添加账号弹窗
    showAddUserDialog (id, name) {
      this.erpUserId = id
      this.addUserDialogVisible = true
    },
    addUserDialogClose () {
      this.$refs.addUserFormRef.resetFields()
    },
    addUser () {
      this.$refs.addUserFormRef.validate(async valid => {
        if (!valid) return
        this.addUserForm.erpUserId = this.erpUserId
        this.addUserForm.deptId = this.addUserForm.deptIdList[this.addUserForm.deptIdList.length - 1]
        erpSysUserIdentityCreate(this.addUserForm).then((res) => {
          this.$message.success('添加身份成功')
          this.addUserDialogVisible = false
          this.processExpansionData(this.erpUserId)
        }).catch((err) => {
          console.log('err', err)
        })
      })
    },
    // 显示修改用户身份弹窗
    showEditUserDialog (rowData) {
      this.editUserForm = rowData
      this.editUserDialogVisible = true
    },
    editUserDialogClose () {
      this.$refs.editUserFormRef.resetFields()
      this.editUserForm = {}
    },
    // 提交用户修改身份
    editUser () {
      this.editUserForm.deptId = this.editUserForm.deptIdList[this.editUserForm.deptIdList.length - 1]
      var userId = this.editUserForm.erpUserId
      erpEditSysUserIdentity(this.editUserForm).then((res) => {
        this.$message.success('修改成功')
        this.editUserDialogVisible = false
        this.processExpansionData(userId)
      })
    },
    // 显示删除用户身份弹窗
    showDeleteUserTip (rowData) {
      this.$confirm('此操作将永久删除该用户身份, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.deleteUserById(rowData)
      }).catch(() => { })
    },
    deleteUserById (rowData) {
      erpDeleteSysUserIdentity({ id: rowData.id }).then((res) => {
        this.$message.success('删除成功')
        this.processExpansionData(rowData.erpUserId)
      }).catch((err) => {
        console.log('err', err)
      })
    },
    processExpansionData (rowId) {
      // this.currentExpandRow = []
      getSysUserIdentityList({ userId: rowId }).then((res) => {
        let currentIndex = 0
        this.accountList.some((item, index) => {
          if (item.id === rowId) {
            currentIndex = index
            return true
          }
        })
        this.currentExpandRow = [rowId] // 控制展开行
        this.$set(this.accountList[currentIndex], 'userRoleList', res.data)
      }).catch((err) => {
        console.log('err', err)
      })
    }
  }
}
</script>

  <style>
</style>
